import React, { useEffect } from "react";
import { useLocation } from "@reach/router";
import { useAuthContext } from "../../../contexts/authContext";
import useURLQueryParameter from "../../../hooks/queryParamHook";
import useNorthstar from "../../../hooks/northstarHook";
import PageLayout from "../../../components/PageLayout";
import PageContent from "../../../components/PageContent";
import PageBoundary from "../../../components/PageBoundary";
import { Link, navigate } from "gatsby";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import {
  CMSAPIDataCollection,
  CMSAPIDataItem,
  CommoditiesDocumentAttributes,
} from "../../../types/cms";
import LoadingStatus from "../../../components/LoadingStatus";
import { getFormattedDate } from "../../../utils";
import CommoditiesReportViewer from "../../../components/CommoditiesReportViewer";

const InsightsIndividualCommoditiesReport = () => {
  const { accessToken, clearIdentity } = useAuthContext();
  const location = useLocation();
  const [report_id, updateReportId] = useURLQueryParameter("report_id");

  useEffect(() => {
    const queryParams = new URLSearchParams(location?.search);
    const newReportId = queryParams.get("report_id");
    if (newReportId !== report_id) {
      updateReportId(newReportId || "");
    }
  }, [location.search]);

  const { data, isLoading, error } = useNorthstar<
    CMSAPIDataCollection<CMSAPIDataItem<CommoditiesDocumentAttributes>>
  >(
    `/api/commodities-documents`,
    accessToken,
    {
      method: "get",
    },
    {
      fields: [
        "report_id",
        "title",
        "report_date",
        "byline",
        "summary",
        "content",
        "commodity",
      ],
      populate: {
        associated_commodity: {
          fields: ["commodity", "commodity_name"],
        },
      },
      filters: {
        report_id: {
          $eq: decodeURIComponent((report_id || "") as string),
        },
      },
    },
  );
  useEffect(() => {
    let ignore = true;
    if (error?.status === 401 && !ignore && clearIdentity) {
      clearIdentity();
    }
    return () => {
      ignore = false;
    };
  }, [error?.status]);
  // Redirect to '/insights' if no report_id is provided
  useEffect(() => {
    if (!report_id) {
      navigate("/insights");
    }
  }, [report_id]);

  let reportDetailsTemplate = (
    <LoadingStatus loadingType="report_details_loading" />
  );

  let tempSEOData = {
    title: "BAM Applied Insights",
    description: "Balyasny's AI-Enabled Research Platform for Core Investors",
  };

  if (error) {
    reportDetailsTemplate = (
      <LoadingStatus loadingType="report_details_error" />
    );
  } else {
    if (isLoading) {
      reportDetailsTemplate = (
        <LoadingStatus loadingType="report_details_loading" />
      );
    } else if (!data || !data.data || !data.data.length) {
      reportDetailsTemplate = (
        <LoadingStatus loadingType="report_details_error" />
      );
    } else {
      const reportData = data.data[0].attributes;
      const { title, byline, report_date, commodity, associated_commodity } =
        reportData;
      const reportDate = getFormattedDate(report_date);
      tempSEOData = {
        title: `${
          title ||
          associated_commodity?.data?.attributes?.commodity_name ||
          commodity
        } (${reportDate})`,
        description: byline,
      };
      reportDetailsTemplate = <CommoditiesReportViewer report={reportData} />;
    }
  }

  return (
    <PageLayout seo={tempSEOData}>
      <PageContent>
        <PageBoundary>
          <nav
            className="flex mt-3 tracking-wide text-lg"
            aria-label="Breadcrumb"
          >
            <div className="flex item-center space-x-4">
              <Link
                to="/insights"
                className="text-blue-800 hover:text-blue-400"
              >
                All Reports
              </Link>
              <ChevronRightIcon
                className="mt-2 h-3 w-3 flex-shrink-0"
                aria-hidden="true"
              />
              <div className="text-blue-500">{tempSEOData.title}</div>
            </div>
          </nav>
          {reportDetailsTemplate}
        </PageBoundary>
      </PageContent>
    </PageLayout>
  );
};

export default InsightsIndividualCommoditiesReport;
