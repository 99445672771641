import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import {
  CMSAPIDataCollection,
  CMSAPIDataItem,
  CommoditiesDocumentAttributes,
} from "../../types/cms";
import {
  EnvelopeIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { ReportDisclaimer, SunshineSupport } from "../../constants";
import { Disclosure } from "@headlessui/react";
import {
  getFormattedDate,
  getCommoditiesDocumentsListQueryParamsFields,
  getReportFilterDateRange,
} from "../../utils";
import MarkdownDisplay from "../MarkdownDisplay";
import useNorthstar from "../../hooks/northstarHook";
import { useAuthContext } from "../../contexts/authContext";
import CommoditiesReportCard from "../CommoditiesReportCard";

interface CommoditiesReportViewerProps {
  report: CommoditiesDocumentAttributes;
}

const CommoditiesReportViewer: React.FC<CommoditiesReportViewerProps> = ({
  report,
}) => {
  const { accessToken, clearIdentity } = useAuthContext();
  const {
    title,
    report_date,
    summary,
    content,
    commodity,
    associated_commodity,
  } = report;
  // temp loading related reports on report viewer. should create a new endpoint for this
  const fields = getCommoditiesDocumentsListQueryParamsFields();
  const {
    data: reportsData,
    isLoading,
    error,
  } = useNorthstar<
    CMSAPIDataCollection<CMSAPIDataItem<CommoditiesDocumentAttributes>>
  >(
    `/api/commodities-documents`,
    accessToken,
    {
      method: "get",
    },
    {
      fields,
      populate: {
        associated_commodity: {
          fields: ["commodity", "commodity_name"],
        },
      },
      filters: {
        commodity: {
          $eq: commodity,
        },
        report_date: {
          $gte: getReportFilterDateRange(),
        },
      },
      sort: { report_date: "desc" },
    },
  );
  const relatedDocuments = reportsData?.data?.length
    ? reportsData?.data?.filter(
        (x) => x.attributes.report_id !== report.report_id,
      )
    : [];
  const reportDate = getFormattedDate(report_date);
  return (
    <AnimatePresence>
      <motion.div
        key="commodities-report-viewer"
        className="flex flex-col w-full mt-3 pt-2 pb-10 px-6 xs:px-8 sm:px-11 md:px-14 lg:px-18 xl:px-20 
      border-t-4 bg-white border-white border-t-blue-900"
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          stiffness: 50,
          duration: 0.4,
        }}
      >
        <div className="my-3 pl-2 pr-8 sm:pl-4 sm:pr-12 pt-3 pb-2">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-semibold">
            {title ||
              associated_commodity?.data?.attributes?.commodity_name ||
              commodity}
          </h1>
          <div className="mt-3 md:mt-4 xl:mt-5 text-sm xs:text-base text-gray-800">
            {reportDate}
          </div>
          <div className="text-blue-800 mt-2 md:mt-3 xl:mt-4">
            <a
              href={`mailto:${SunshineSupport}`}
              className="flex gap-x-1 tracking-wide text-lg"
            >
              <div>
                <span className="sr-only">Email</span>
                <EnvelopeIcon className="w-6 h-6 mt-px" />
              </div>
              Contact
            </a>
          </div>
        </div>
        <div className="mt-1 xl:text-lg">
          <Disclosure defaultOpen={true} as="div">
            {({ open = true }) => (
              <>
                <Disclosure.Button className="flex w-full items-start justify-between text-left py-4 sm:py-5 md:py-6 px-2 sm:px-4 border-t border-t-gray-300 hover:bg-gray-50">
                  <h2 className="text-lg sm:text-xl font-semibold text-gray-800">
                    Summary
                  </h2>
                  <div className="flex">
                    <span className="mt-1 ml-6 flex h-6 items-center">
                      {open ? (
                        <ChevronDownIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <ChevronRightIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </span>
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel
                  as="div"
                  className="pl-2 pr-8 sm:pl-4 sm:pr-12 pt-0.5 md:pt-1 pb-4 xs:pb-5 md:pb-6 lg:pb-7 xl:pb-8 leading-relaxed"
                >
                  <MarkdownDisplay markdownHtml={summary} />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure defaultOpen={true} as="div">
            {({ open = true }) => (
              <>
                <Disclosure.Button className="flex w-full items-start justify-between text-left py-4 sm:py-5 md:py-6 px-2 sm:px-4 border-t border-t-gray-300 hover:bg-gray-50">
                  <h2 className="text-lg sm:text-xl font-semibold text-gray-800">
                    Details
                  </h2>
                  <div className="flex">
                    <span className="mt-1 ml-6 flex h-6 items-center">
                      {open ? (
                        <ChevronDownIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <ChevronRightIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </span>
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel
                  as="div"
                  className="pl-2 pr-8 sm:pl-4 sm:pr-12 pt-0.5 md:pt-1 pb-4 xs:pb-5 md:pb-6 lg:pb-7 xl:pb-8 leading-relaxed"
                >
                  <div className="flex flex-col justify-center items-center">
                    <MarkdownDisplay markdownHtml={content} />
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
        <section className="mt-6">
          <h2 className="sr-only">Disclaimer</h2>
          <div className="p-4 pr-10 text-xxs sm:text-xs tracking-tight leading-snug text-bam-medium-gray">
            {ReportDisclaimer}
          </div>
        </section>
      </motion.div>
      {relatedDocuments.length > 0 && (
        <div key="related-reports" className="flex flex-col w-full mt-8">
          <h2 className="text-xl text-blue-800">More Reports on {commodity}</h2>
          <div className="grid grid-cols-1 gap-4 mt-3 sm:grid-cols-2 lg:grid-cols-3">
            {relatedDocuments.map((doc) => (
              <div key={doc.id} className="flex flex-col w-full">
                <CommoditiesReportCard
                  document={doc.attributes}
                  reportDetailsView
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default CommoditiesReportViewer;
