import React from "react";
import { DateTime } from "luxon";

interface NewTagProps {
  reportDate?: string;
}

const NewTag: React.FC<NewTagProps> = ({ reportDate }) => {
  const now = DateTime.now();
  let isNew = false;
  if (reportDate) {
    const parsedDate = DateTime.fromISO(reportDate);
    if (parsedDate.isValid) {
      isNew = parsedDate.diff(now, "days").days >= -3;
    }
  }

  return isNew ? (
    <div className="px-2 py-0.5 bg-blue-400 text-white align-middle">New</div>
  ) : null;
};

export default NewTag;
